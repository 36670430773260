import { Modal } from "react-bootstrap";
import styled from "styled-components";

const RewardsDashboardTopSection = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

const TopComponentMainDiv = styled.div`
    width: 1246px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #FFF;
    margin-top: 25px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .navigationTabs{
        display: inline-flex;
        // padding: 10px 15px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 6px;
        
        color: var(--navy_blue, #005C87);
        text-align: center;
        font-family: Rubik-medium;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        border: 0px;
        border-radius: 6px;

        .navTab{
            display: flex;
            padding: 10px 15px;
            align-items: flex-start;
            gap: 10px;
            cursor: pointer;
            border-radius: 6px;

            &:hover{
                background: #005C87;
                color: #ffff;
            }
        }

        .activeTab{
           display: flex;
            padding: 10px 15px;
            align-items: flex-start;
            gap: 10px;
            border-radius: 4px;
            background: #007AB1;
            color: #FFF;
            text-align: center;
            font-family: Rubik-medium;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 125% */
        }
    }

    .pointsActions{
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        padding-right: 8px;

        .iconsDiv{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            padding: 8px;
            border-radius: 4px;
            background: rgba(0, 122, 177, 0.05);
            cursor: pointer;
        }

        .manageRewardsButton{
            width: 187px;
            height: 40px;
            flex-shrink: 0;
            border-radius: 6px;
            border: 1px solid rgba(0, 92, 135, 0.30);
            display: flex;
            flex-direction: row;
            display: flex;
            align-items: center;
            gap: 10px;
            margin-right: 11px;

            .manageRewardsImage{
                width: 40px;
                height: 40px;
                flex-shrink: 0;
            }
            .hoverManageRewardsImage{
                background-color: #015c87;
                border-radius: 6px 0px 0px 6px;
            }
            .manageRewardsText{
                color: #005C87;
                text-align: center;
                font-family: Rubik;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 125% */
                cursor: pointer;
            }
            .darkManageRewardButtonText{
                color: #FFF;
            }
        }
        .hoveredManageRewardsButton{
            border: 1px solid rgba(0, 92, 135, 0.30);
            background: #005C87;
        }
    }

`;

const RewardsModalContainer = styled.div`
    width:100%;
    display:flex;
    padding: ${({padding, paddingValue}) => paddingValue?paddingValue:padding ? "15px 25px 20px 25px" : '25px 25px 35px 25px'};
    flex-wrap: wrap;
    border: ${({border}) => border ? "" : '1px solid #9C9C9C66'};

    .notMatched{
        font-family:rubik;
        font-size:12px;
        color:#F4AAA9;
        line-height:20px;
        margin-top:5px;
    }
`;

const StyledModalRewardsManagementForm = styled(Modal)`
    .modal-dialog {
        transform: ${({transform}) => transform ? 'translate(0, 15vh) translate(0, -15%) !important' : 'translate(0, 50vh) translate(0, -50%) !important'};
        margin: ${({change}) => change ? '150px auto' : '0 auto'};
        
        .modal-content{
            border:none;
            margin-top: 10%;
            width: 600px;
        }
        
        @media (max-height: 460px) {
            transform: none !important;
            margin: 30px auto;
        }
        width: ${({width}) => width ? width : '490px'};
        height: ${({change, height}) => height? height :change ? '573px' : '473px'};

        @media (max-width: 500px){
            width: 95%;
            height: auto;
        }
    }

    .modal-body{
        padding: ${({padding}) => padding ? padding : '25px 0 25px 0'};
      }
      
      .modal-container {
       width: 100%;
       display: block;
       border: none;
       > div: nth-child(1) {
        width: 100%;
        height: 0px;
        display: flex;
        justify-content: flex-end;
        >img{
          width: 24px;
          height: 24px;
          cursor: pointer;
          margin: 15px 15px 0 0;
        }
       }
       > div: nth-child(2) {
        width: 100%;
        display:flex;
        justify-content: center;
        font-family: Rubik-Medium;
        font-size: 24px;
        line-height: 24px;
        color:#0D4270;
        text-decoration: underline;
        margin-top: 35px;
       }
      }
      .modalHeaderSection{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 600px;
        height: 60px;
        flex-shrink: 0;
        align-items: center;
        padding-left: 25px;
        padding-right: 18px;
        border-bottom: 1px solid rgba(0, 92, 135, 0.60);

        .modalHeaderSectionText{
            color: #005C87;
            font-family: Rubik-medium;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 133.333% */
        }

        .modalHeaderSectionClose{
            cursor: pointer;
        }
      }
`;

const SecondComponentMainDiv = styled.div`
    height: 80px;
    width: 1246px;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    gap: 25px;
`;

const CompanyMedalCardsDashboardMainDiv = styled.div`
    width: 292px;
    height: 190px;
    flex-shrink: 0;
    flex-shrink: 0;
    border-radius: 6px;
    background: #FFF;
    display: flex;

    .cardDiv{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 15px;
        gap: 15px;
        margin-top: 12px;

        .cardFirstRow{
            display: flex;
            flex-direction: row;
            .cardImage{
                width: 48px;
                height: 48px;
                flex-shrink: 0;
                border-radius: 6px;
                // background: #f0f5f8;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 40px;
                    height: 40px;
                    flex-shrink: 0;
                }
            }
            .cardDetails{
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;

                .cardTitle{
                    height: 100%;
                    color: #005C87;
                    font-family: Rubik-medium;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    display:flex;
                    align-items: center;
                    padding-left: 12px;
                    text-transform: capitalize;
                }
            }
        }

        .cardSecondRow{
            .cardPoints{
                color: #85C0EA;
                font-family: Rubik-medium;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 100% */

                .coloredPoints{
                    color: var(--sky_blue, #85C0EA);
                    font-family: Rubik-medium;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px; /* 100% */
                }

                span{
                    color: rgba(0, 92, 135, 0.60);
                    font-family: Rubik;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 142.857% */
                }
            }
        }
        .cardThirdRow{
            hr{
                margin: 10px 0px;
            }
        }
        .cardFourthRow{
            .coloredText{
                font-family: Rubik-medium;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 142.857% */
            }
            .normalText{
                color: rgba(0, 92, 135, 0.60);
                font-family: Rubik;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }

`;

const CompanyRewardsDetailsMainDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border-bottom: 1px solid rgba(0, 92, 135, 0.30);

    .welcomeText{
        color: #F4AAA9;
        text-align: center;
        font-family: Rubik-medium;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 133.333% */
        margin-top: 25px;
        margin-bottom: 12px;
    }

    .welcomeSubText{
        color: #005C87;
        text-align: center;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-top: 9px;
        margin-bottom: 20px;
    }
    .medalCardMainDiv{
        display: flex;
        flex-direction: column;
        margin-bottom: 34px;
    }
    .verticalLineImage{
        margin-left: 30px;
        width: 20px;
        position: relative;
    }
`;

const MedalCardsUIDiv = styled.div`
    width: 490px;
    height: 80px;
    flex-shrink: 0;
    border-radius: 6px;
    border: ${({borderColor}) => borderColor && '1px solid '+borderColor};
    background: #FFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    padding-right: 18px;
    padding-left: 15px;

    .medalImage{
        width: 50px;
        height: 50px;
        flex-shrink: 0;
        border-radius: 6px;
        background: #f0f5f8;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
            width: 30px;
            height: auto;
        }
    }
    .medalDetails{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .medalTitle{
            color: #A57576;
            font-family: Rubik-medium;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 111.111% */
        }
        .medalPointsText{
            color: #005C87;
            text-align: right;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 125% */
        }
    }
`;

const SelectCompanyDropdownDiv = styled.div`
    width: ${({width})=> width}
    border: 1px solid black;

    .react-select__control .react-select__dropdown-indicator{
        display: none;
    }
`;

const ModalFooterSectionMainDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: ${({stepCount}) => stepCount !== 5 && '1px solid rgba(0, 92, 135, 0.30)' };

    .footerMainText{
        color: #005C87;
        text-align: center;
        font-family: Rubik-medium;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 133.333% */
        margin-top: 25px;
        margin-bottom: 23px;
    }

    .footerButtonsDiv{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: ${ ({stepCount}) => stepCount===5 ? 'space-around' : 'space-between'};
        padding-left: 25px;
        padding-right: 25px;
        margin-bottom: 15px;
        margin-top: 15px;

        .leftButton{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 50px;
            flex-shrink: 0;
            border-radius: 3px;
            border: 1px solid rgba(0, 92, 135, 0.30);
            color: var(--navy-60, rgba(0, 92, 135, 0.60));
            text-align: center;
            font-family: Rubik-medium;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 133.333% */
            cursor: pointer;
            user-select: none;
        }
        .rightButton{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 50px;
            flex-shrink: 0;
            border-radius: 3px;
            background: #005C87;
            box-shadow: 0px 8px 25px 0px rgba(0, 92, 135, 0.25);
            color: #FFF;
            text-align: center;
            font-family: Rubik-medium;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 133.333% */
            cursor: pointer;
            user-select: none;
        }
    }
    .footerConfirmationText{
        color: #005C87;
        text-align: center;
        font-family: Rubik-medium;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
        margin-top: ${({stepCount}) => stepCount !== 5 && '25px' };
        
        .confirmationDate{
            color: var(--Health_primary, #F4AAA9);
            font-family: Rubik-medium;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }
    }
`;



const StepsMainDiv = styled.div`
    display: flex;
    flex-direction: row;   
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    gap: 5px;
    .numberArrowDiv{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
    }
    .activeStep{
        background: #005C87;
        color: #FFF;
        font-family: Rubik-medium;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 100% */
    }
`;

const StepNumber = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-top: 25px;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 3px;
    background: ${({activeStep}) => activeStep ? '#005C87' : 'rgba(0, 92, 135, 0.04)'} ;
    color: ${({activeStep}) => activeStep ? '#FFF' : 'rgba(0, 92, 135, 0.60)'};
    font-family: Rubik-medium;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 100% */

    .stepArrowImage{
        position: relative !important;
        top: 13px !important;
    }
`;

const SetGoalTextMainDiv = styled.div`
    height: auto;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(0, 92, 135, 0.30);

    .titleText{
        color: #005C87;
        text-align: center;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
        margin-bottom: 10px;
        .badgeText{
            font-family: Rubik-medium;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
        }
    }
    .subText{
        color: rgba(0, 92, 135, 0.60);
        text-align: center;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-bottom: 15px;
    }
`;

const PointOptionSection = styled.div`
    width: 490px;
    margin-left:55px;
    margin-right: 55px;

    .orLine{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: rgba(0, 92, 135, 0.60);
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        gap: 6px;
        margin-top: 15px;
        margin-bottom: 15px;

        hr{
            width: 100%;
            background: rgba(0, 92, 135, 0.30);
        }
    }

    .customOptionDiv{
        display: flex;
        flex-direction: column;

        .customInputBox{
            width: 490px;
            height: 60px;
            flex-shrink: 0;
            border-radius: 6px;
            border: 1px solid rgba(0, 92, 135, 0.30);
            background: rgba(247, 245, 235, 0.50);
            padding-top: 18px;
            padding-bottom: 18px;
            padding-left: 15px;
            
            color: #005C87;
            font-family: Rubik-medium;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
            margin-top: 5px;
            margin-bottom: ${ ({showRangeError}) => !showRangeError && '44px'};

            ::placeholder{
                color: rgba(0, 92, 135, 0.60);
                font-family: Rubik;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
            }
        }

        .rangeWarningMessage{
            color: var(--Health_primary, #F4AAA9);
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            margin-bottom: 45px;
        }
    }

    .defaultText{
        color: #005C87;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        margin-bottom: 15px;
    }

    .defaultOptionDiv{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        border-radius: 6px;
        border: 1px solid rgba(0, 92, 135, 0.60);
        background: #FFF;
        padding: 15px;

        .medalImage{
            width: 50px;
            height: 50px;
            flex-shrink: 0;
            border-radius: 6px;
            background: #f0f5f8;
            display: flex;
            justify-content: center;
            align-items: center;

            img{
                width: 30px;
                height: auto;
            }
        }

        .medalDetails{
            width: 90%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 10px;
        }

        .medalTitle{
            color: #005C87;
            font-family: Rubik-medium;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 125% */
        }

        .optionCheck{    
            
        }
    }
`;

const OptionCheck = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 22px;
    height: 22px;
    border: 1px solid rgba(0, 92, 135, 0.60);
    border-radius: 50%;
    padding: 3px;

    .checkboxInput{
        width: 14px;
        height: 14px;
        background-color: white;
        border-radius: 50%;
        vertical-align: middle;
        appearance: none;
        -webkit-appearance: none;
        outline: none;
        cursor: pointer;
        margin: 0px;
        
        :checked{
            background-color: ${({isSelected}) => isSelected && '#005C87' } ;
        }
        :focus{
            outline: none;
        }
    }
`;

const RewardConfirmationMainDiv = styled.div`

    .mainTitle{
        color: #AF87C1;
        text-align: center;
        font-family: Rubik-medium;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 133.333% */
        margin-top: 25px;
        margin-bottom: 13px;
        
    }
    .subTitleText{
        color: #005C87;
        text-align: center;
        font-family: Rubik-medium;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 133.333% */
        margin-bottom: 15px;

        .badgesPointText{
            color: #AF87C1;
            font-family: Rubik-medium;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }
    }
    .descriptionText{
        color: #005C87;
        text-align: center;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-bottom: 25px;
    }
    .medalCardMainDiv{
        display: flex;
        flex-direction: column;
        align-items: center;
        // border-bottom: 1px solid rgba(0, 92, 135, 0.60);
    }
    .verticalLineImage{
        margin-left: 30px;
        width: 20px;
        position: relative;
        left: -220px;
    }
`;

const LeftButton = styled.div`
    display: flex;
    justify-content: ${ ({isBackButton}) => isBackButton ? 'left' : 'center'};
    align-items: center;
    width: ${ ({isBackButton}) => isBackButton ? '140px' : '200px'};
    height: 50px;
    flex-shrink: 0;
    border-radius: 3px;
    border: ${ ({isBackButton}) => isBackButton ? '0px solid rgba(0, 92, 135, 0.30)' : '1px solid rgba(0, 92, 135, 0.30)'};
    color: ${({currentStepCount})=> currentStepCount === 5 ? '#FFF' : 'rgba(0, 92, 135, 0.60)'};
    text-align: center;
    font-family: Rubik-medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 133.333% */
    cursor: pointer;
    user-select: none;
    padding-left: ${({isBackButton}) => isBackButton && '35px'};
    background: ${({currentStepCount})=> currentStepCount === 5 ? 'linear-gradient(133deg, #AF87C1 25.62%, #6781C3 73.49%)' : '#FFF' };
`;

const RightButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 3px;
    background: ${ ({currentStepCount}) => currentStepCount!==5 && '#005C87'};
    background: ${ ({isRightButtonDisabled}) => isRightButtonDisabled && 'rgba(0, 92, 135, 0.30)'};
    // box-shadow: 0px 8px 25px 0px rgba(0, 92, 135, 0.25);
    border: ${ ({currentStepCount}) => currentStepCount===5 && ' 1px solid rgba(0, 92, 135, 0.30)'};
    color: ${ ({currentStepCount}) => currentStepCount!==5 ? '#FFF' : 'rgba(0, 92, 135, 0.60)'};
    text-align: center;
    font-family: Rubik-medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 133.333% */
    cursor: pointer;
    user-select: none;
    pointer-events: ${ ({isRightButtonDisabled}) => isRightButtonDisabled && 'none'};
`;

const ThankYouMainDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 485px;
    height: 462px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #FFF;
    justify-content: center;
    align-items: center;
    padding-top: 25px;

    .headerSection{
        margin-bottom: 21px;
        display: flex;
        flex-direction: column;

        .title{
            color: #005C87;
            text-align: center;
            font-family: Rubik-medium;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
            text-align: center;
        }
        .subText{
            color: #005C87;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
        }
    }

    .imageSection{
        display: flex;
        justify-content: center;
        img{
            width: 250px;
            height: 166px;
            flex-shrink: 0;
        }
    }

    .afterImageSection{
        color: #005C87;
        text-align: center;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        text-align: center;
        margin-top: 12px;
        margin-bottom: 20px;
    }

    .thankYouButton{
        width: 369px;
        height: 49px;
        flex-shrink: 0;
        border-radius: 4px;
        background: #F4AAA9;
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.12);
        color: #FFF;
        text-align: center;
        font-family: Rubik-medium;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
        cursor: pointer;
    }

`;

const DocumentDashboardMainDiv = styled.div`
    border: 1px solid black;
    width: 100%;
    height: 400px;
    background-color: white;


`;

const UploadDocumentThumbnail = styled.div`
    width: 292px;
    height: 342px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #faf9f3;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .innerThumbnail{
        width: 272px;
        height: 322px;
        flex-shrink: 0;
        border-radius: 6px;
        border: 1px dashed rgba(0, 92, 135, 0.30);
        background: #FFF;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        #document-file{
            display: none;
        }
    }

    img{
        width: 100px;
        height: 100px;
        flex-shrink: 0;
        cursor: pointer;
    }

    .uploadDocumentText{
        color: #005C87;
        text-align: center;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
    }

`;

const UploadDocumentThumbnailNew = styled.div`
    display: flex;
    width: 400px;
    padding: 74px 16px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    width: 400px;
    height: 308px;
    border: 1px solid rgba(0, 92, 135, 0.60);

    .innerThumbnail{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 368px;
        gap: 24px;
        flex-shrink: 0;

        .uploadDocumentTitle{
            color: #007AB1;
            text-align: center;
            font-family: Rubik-medium;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 125% */

            span{
                color: #007AB1;
                font-family: Rubik;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 20px;
            }
        }
        
        .uploadDocumentButton{
            display: flex;
            height: 48px;
            padding: 12px 20px;
            justify-content: center;
            align-items: center;
            gap: 12px;
            align-self: stretch;
            border-radius: 4px;
            background: #007AB1;
            border: none;
            cursor: pointer;
            color: #FFF;
            text-align: center;
            font-family: Rubik-medium;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 133.333% */

            label{
                cursor: pointer;
            }
        }
    }

    .fileInputWrapper{
        .file-input {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
        }
        .file-input-label {
            display: inline-block;
            padding: 10px 20px;
            font-size: 16px;
            font-family: Arial, sans-serif;
            color: white;
            background-color: blue;
            border-radius: 5px;
            cursor: pointer;
        }
    }
`;


const StyledPdfContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; // This ensures the PDF stays centered
`;


const DocumentImage = styled.div`
    width: 100%;
    height: 260px;
    flex-shrink: 0;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    position: relative;
    background-color: white;
    border-radius: 4px;

    img{
        width: 400px;
        height: 252px;
        flex-shrink: 0;
        filter: ${ ({isHovered}) => isHovered && 'brightness(60%)' };
        border-radius: 6px 6px 0px 0px;
    }
    
    .buttonGroup{
        display: ${ ({isHovered}) => isHovered ? 'flex' : 'none'};
        flex-direction:  ${ ({isDirection}) => isDirection ? isDirection : 'column'};
        justify-content: center;
        position: absolute;
        margin-top: 120px;
        gap: 15px;

        .previewButton{
            width: 200px;
            height: 40px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            background: #FFF;
            color: #005C87;
            text-align: center;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 125% */
            cursor: pointer;
        }
        .previewFileButton{
            width: 140px;
            height: 48px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            gap: 12px;
            justify-content: center;
            background: #005C87;
            color: #FFF;
            text-align: center;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 125% */
            cursor: pointer;
            padding: 12px 20px;
            border-radius: 4px;
            background: var(--Buttons, #007AB1);
        }


        .downloadFileButton{
            ${'' /* width: 48px; */}
            ${'' /* height: 48px; */}
            flex-shrink: 0;
            display: flex;
            align-items: center;
            gap: 12px;
            justify-content: center;
            background: #005C87;
            color: #FFF;
            text-align: center;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; 
            cursor: pointer;
            padding: 12px 20px;
            border-radius: 4px;
            background: var(--Buttons, #007AB1);
        }
    }
`;

const UploadDiv = styled.div`
    height: 700px;
    overflow: auto;
    display: flex;
    gap: 22px;
    flex-wrap: wrap;
    padding-bottom: 50px;
    ::-webkit-scrollbar{
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

const FileDocumentThumbnail = styled.div`
    // width: 292px;
    // height: 342px;
    // flex-shrink: 0;
    // border-radius: 6px;
    // border: 1px solid rgba(0, 92, 135, 0.30);
    // background: #faf9f3;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    width: 400px;
    height: 252px;
    flex-shrink: 0;
    border-radius: 6px 6px 0px 0px;
    background: #fff;
    border-radius: 4px;
    background: #FFF;
`;

const DocumentDetails = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    height: 100%;
    background-color: #fff;

    .documentTitleInput{
        width: 190px;
        height: 40px;
        flex-shrink: 0;
        border-radius: 3px;
        border: 1px solid rgba(0, 92, 135, 0.30);
        background: #FFF;
        padding-left: 8px;

        color: var(--navy_blue, #005C87);
        text-align: left;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
    }

    .title{
        color: #005C87;
        font-family: Rubik-medium;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 133.333% */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        display: inline-block;
    }

    .documentOptionsDiv{
        display: flex;
        flex-direction: column;
        height: 40px;
        background-color: #ebf0ed;
        display: flex;
        justify-content: center;
        padding: 8px;
        border-radius: 6px;
        position: relative;
        background-color: #fff;

        .dropdown
        {
            position: absolute;
            
            button{
                background-color: transparent;
                border: 0px;
                position: absolute;
                top: -20px;
                left: -5px;
            }
            :active{
                border: none;
            }
            .dropdown-menu.show{
                width: 95px;
                height: 88px;
                flex-shrink: 0;
                border-radius: 3px 3px 3px 3px;
                border: 1px solid rgba(0, 92, 135, 0.30);
                background: #FFF;
                box-shadow: 0px 4px 4px 0px rgba(0, 92, 135, 0.05);
                transform: translate3d(-100px, 22px, 0px) !important;
                display: flex;
                flex-direction: column;
                align-items: center;
<<<<<<< HEAD
                justify-content: center;
=======
                left:0 !important;
>>>>>>> ec2842d5d452ff1588d28c6356e1be040501ffad

                hr{
                    margin: 0px;
                    height: 1px; 
                    background-color: rgba(0, 92, 135, 0.50);
                    width: 80%;
                    border: 0px solid black;
                }
                a{
                    text-align: left;
                    height: 100%;
                    color: #007AB1;
                    text-align: left;
                    font-family: Rubik-medium !important;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    // line-height: 20px; /* 166.667% */

                    :active{
                        background-color: #fff;
                    }
                }
            }
        }

        div > img{
            width: 25px;
            display: flex;
            position: relative;
        }
    }
`;

const DeleteDocumentModal = styled(Modal)`
    width: auto;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .modal-dialog{
        width: 485px;
        height: 382px;
    }
    .modalContent{
        width: 485px;
        height: 380px;
        flex-shrink: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .image{
            img{
                width: 100px;
                height: 100px;
                margin-top: 35px;
                margin-bottom: 26px;
            }
        }
    }
    .modalHeader{
        height: 50px;
        flex-shrink: 0;
        border-radius: 6px 6px 0px 0px;
        background: #005C87;
        color: #FFF;
        text-align: center;
        font-family: Rubik-medium;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 133.333% */
        text-transform: capitalize;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .messageSection{
        .mainMessage{
            color: #005C87;
            text-align: center;
            font-family: Rubik;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 133.333% */
            
            span{
                color: #005C87;
                font-family: Rubik-medium;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }
        }
        .subMessage{
            color: rgba(0, 92, 135, 0.60);
            text-align: center;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 125% */
            margin-bottom: 25px;
            margin-top: 15px;
        }
    }
    .modalFooter{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 35px;
        .yesButton{
            width: 200px;
            height: 50px;
            flex-shrink: 0;
            border-radius: 3px;
            background: #005C87;
            box-shadow: 1px 4px 10px 0px rgba(0, 92, 135, 0.40);
            color: #FFF;
            text-align: center;
            font-family: Rubik-medium;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 133.333% */
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        .cancelButton{
            width: 200px;
            height: 50px;
            flex-shrink: 0;
            border-radius: 3px;
            border: 2px solid rgba(0, 92, 135, 0.60);
            color: rgba(0, 92, 135, 0.60);
            text-align: center;
            font-family: Rubik-medium;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 133.333% */
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
`;

const PreviewModalMainDiv = styled(Modal)`
    width: auto;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CustomPopupModalDiv = styled(Modal)`
    width: 400px;
    height: 336px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;

    .titleDiv{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 0px;   
        justify-content: center;
        border-bottom: 1px solid rgba(0, 92, 135, 0.30);
        .titleText{
            color: #005C87;
            text-align: center;
            font-family: Rubik-medium;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 133.333% */
        }
    }
    .messageDiv{
        color: #005C87;
        text-align: center;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
        color: var(--Navy_blue, #005C87);
        padding: 24px;
    }

    .buttonDiv{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
        padding-bottom: 24px;

        .submitButton{
            display: flex;
            width: 352px;
            height: 48px;
            padding: 4px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            flex-shrink: 0;
            border-radius: 4px;
            background: #007AB1;
            color: var(--White, #FFF);
            text-align: center;
            font-family: Rubik-medium;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 133.333% */
        }

        .cancelButton{
            display: flex;
            width: 352px;
            height: 48px;
            padding: 4px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            flex-shrink: 0;
            border-radius: 4px;
            border: 1px solid #007AB1;
            color: var(--Buttons, #007AB1);
            text-align: center;
            font-family: Rubik-medium;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 133.333% */
        }
    }
`

const RewardsBucketFormMainDiv = styled.div`
    ${'' /* width: 90%;
    padding: 8px; */}
    
    .headerDiv{
        margin-top:24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: white;
        width: 100%;
        padding: 8px;
        gap: 12px;
        border-radius: 4px;
        background: #FFF;
        width: 1248px;
        
        .closeIcon{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            background-color: rgba(0, 122, 177, 0.05);
            cursor: pointer;
        }
        .headerText{
            color: #005C87;
            text-align: center;
            font-family: Rubik-medium;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 133.333% */
        }
    }
    .mainContainer{
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        gap: 20px;

        .leftSection{
            display: inline-flex;
            padding: 20px 40px 236px 20px;
            flex-direction: column;
            align-items: center;
            border-radius: 4px;
            background: #FFF;
            
            .stepsDiv{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 20px;

                .steps{
                    display: flex;
                    width: 40px;
                    height: 40px;
                    padding: 8px 14px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 3px;
                    background: #005C87;
                    color: #FFF;
                    text-align: center;
                    font-family: Rubik-medium;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px; /* 100% */
                }
                .stesText{
                    color: #005C87;
                    font-family: Rubik-medium;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px; /* 133.333% */
                }
            }
        }
        .rightSection{
            width: 924px;
            height: 564px;
            flex-shrink: 0;
            background-color: white;
            padding: 16px;

            .headerSection{
                display: flex;
                flex-direction: row;
                gap: 20px;
                border-bottom: 1px solid rgba(0, 92, 135, 0.30);
                padding-bottom: 16px; 
                align-items: center;

                .steps{
                    display: flex;
                    width: 40px;
                    height: 40px;
                    padding: 8px 14px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 3px;
                    background: #005C87;
                    color:  #FFF;
                    text-align: center;
                    font-family: Rubik-medium;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px; /* 100% */
                }
                .headerText{
                    color: #005C87;
                    font-family: Rubik-medium;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px; /* 133.333% */
                }
            }
            .fieldSection{
                display: flex;
                flex-direction: column;
                gap: 16px;
                margin-top: 20px;

                .titleSection{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .left{
                        .mainText{
                            color: #005C87;
                            font-family: Rubik;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px; /* 125% */
                        }
                        .subText{
                            color: #005C87;
                            font-family: Rubik-Light;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 20px;
                        }
                    }
                    .right{
                        display: flex;
                        gap: 7px;
                        .defaultText{
                            color: #005C87;
                            font-family: Rubik;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px; /* 150% */
                        }
                    }
                }
                .inputDiv{
                    .inputField{
                        width: 100%;
                        display: flex;
                        width: 892px;
                        height: 48px;
                        padding: 4px 12px;
                        align-items: center;
                        gap: 4px;
                        border-radius: 4px;
                        border: 1px solid rgba(0, 122, 177, 0.30);
                        background: #FFF;
                        &::placeholder{
                            color: rgba(0, 92, 135, 0.30);
                            font-family: Rubik;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px; /* 125% */
                        }
                    }
                }
            }
        }
    }
`;

const ButtonContainer = styled.div`
    width: 100%;
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: right;
    display: flex;
    height: 80px;
    justify-content: right;
    align-items: center;
    flex-shrink: 0;
    background: #FFF;
    margin-left: 80px;margin-left: 80px;

    .doneButton{
        display: flex;
        width: 200px;
        height: 48px;
        padding: 4px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex-shrink: 0;
        border-radius: 4px;
        background: ${ ({isDoneButtonEnabled}) => isDoneButtonEnabled ? "rgba(0, 122, 177, 0.30)" : "#005C87"};
        margin-right: 200px;
        border: none;
        color: #FFF;
        text-align: center;
        font-family: Rubik-medium;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 133.333% */
        pointer-event: ${ ({isDoneButtonEnabled}) => isDoneButtonEnabled && "none" };

        span{
            width: 24px;
            height: 24px;
            flex-shrink: 0;
        }
    }
`;

export {RewardsDashboardTopSection, TopComponentMainDiv, RewardsModalContainer, StyledModalRewardsManagementForm, SecondComponentMainDiv, CompanyMedalCardsDashboardMainDiv, CompanyRewardsDetailsMainDiv, SelectCompanyDropdownDiv, MedalCardsUIDiv, ModalFooterSectionMainDiv, StepsMainDiv, StepNumber, SetGoalTextMainDiv, PointOptionSection, RewardConfirmationMainDiv, LeftButton, OptionCheck, ThankYouMainDiv, RightButton, DocumentDashboardMainDiv, UploadDocumentThumbnail, UploadDocumentThumbnailNew, UploadDiv, FileDocumentThumbnail, DocumentImage,StyledPdfContainer, DocumentDetails, DeleteDocumentModal, PreviewModalMainDiv, CustomPopupModalDiv, RewardsBucketFormMainDiv, ButtonContainer};